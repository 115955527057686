import gql from 'graphql-tag';

export const GET_STORES = gql`
  query stores {
    stores {
      id
      name
      plan {
        active
      }
      store
      shouldUpdateAppInstall
      isStoreShopifyPlus
      domains {
        id
        domain
      }
      completedSteps
    }
  }
`;

export const GET_USER_STORE_NOTIFICATIONS = gql`
  query storeUserNotifications($storeId: uuid!) {
    storeUserNotifications(where: { storeId: { _eq: $storeId } }) {
      claimNew
      claimRefund
      claimReorder
      claimStatus
      id
      storeId
      userId
      store {
        users {
          user {
            avatarUrl
            displayName
            email
            id
            notifications {
              id
            }
          }
          userRole {
            role
          }
        }
      }
      user {
        avatarUrl
        displayName
        email
        id
      }
    }
  }
`;
export const GET_USER_ROLE = gql`
  query storeRoles($storeId: uuid!, $userId: uuid!) {
    storeRoles(
      where: {
        stores: { storeId: { _eq: $storeId }, userId: { _eq: $userId } }
      }
    ) {
      role
    }
  }
`;

export const GET_PROTECTION_SETTINGS = gql`
  query protectionSettings($storeId: uuid!) {
    protectionSettings(where: { storeId: { _eq: $storeId } }) {
      defaultFee
      highestOrderValue
      minimumFee
      maximumFee
      percentage
      protectionType
      storeId
      updatedAt
      id
      rules
    }
  }
`;

/************
 *
 * ORDERS query
 * **********
 */

export const GET_ORDERS = gql`
  query orders(
    $storeId: uuid!
    $limit: Int
    $offset: Int
    $order_by: [orders_order_by]!
  ) {
    orders(
      where: { storeId: { _eq: $storeId } }
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      isReorder
      cancelledAt
      number
      orderId
      protectionStatus
      protectionTotal
      total
      customerName
      customerEmail
      customerPhone
      createdAt
      id
      subtotal
      trackingStatus
      trackings(where: { status: { _eq: "ACTIVE" } }) {
        trackingNumber
        trackingCompany
        defaultTrackingUrl
        status
        trackerId
        trackerStatus
        estDeliveryDate
        orderItem {
          title
          quantity
        }
      }
      claims {
        id
        number
      }
      items {
        id
        trackingId
        title
      }
    }
    ordersAggregate(where: { storeId: { _eq: $storeId } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ORDERS_COUNT = gql`
  query getOrdersCount($storeId: uuid!) {
    totalOrders: ordersAggregate(where: { storeId: { _eq: $storeId } }) {
      aggregate {
        total: count
      }
    }
    unprotectedOrders: ordersAggregate(
      where: { storeId: { _eq: $storeId }, protectionTotal: { _is_null: true } }
    ) {
      aggregate {
        total: count
      }
    }
    protectedOrders: ordersAggregate(
      where: {
        storeId: { _eq: $storeId }
        protectionTotal: { _is_null: false }
      }
    ) {
      aggregate {
        total: count
      }
    }
  }
`;

export const GET_ORDERS_BY = gql`
  query orders(
    $order_by: [orders_order_by]!
    $storeId: uuid!
    $limit: Int
    $offset: Int
  ) {
    orders(
      order_by: $order_by
      where: { storeId: { _eq: $storeId } }
      limit: $limit
      offset: $offset
    ) {
      isReorder
      number
      cancelledAt
      orderId
      protectionStatus
      protectionTotal
      customerName
      customerEmail
      customerPhone
      createdAt
      id
      subtotal
      trackingStatus
      trackings(where: { status: { _eq: "ACTIVE" } }) {
        trackingNumber
        trackingCompany
        defaultTrackingUrl
        status
        trackerId
        trackerStatus
        estDeliveryDate
        orderItem {
          title
          quantity
        }
      }
      claims {
        id
        number
      }
      items {
        id
        title
        trackingId
      }
    }
    ordersAggregate(
      order_by: $order_by
      where: { storeId: { _eq: $storeId } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ORDER_BY_ID = gql`
  query orders($storeId: uuid!, $id: uuid!, $limit: Int, $offset: Int) {
    orders(
      where: { storeId: { _eq: $storeId }, id: { _eq: $id } }
      limit: $limit
      offset: $offset
    ) {
      number
      orderId
      protectionStatus
      protectionTotal
      total
      discountTotal
      customerName
      customerEmail
      customerPhone
      isReorder
      cancelledAt
      createdAt
      updatedAt
      id
      subtotal
      paymentCurrencyCode
      claims {
        id
      }
      items {
        imageSrc
        orderId
        price
        quantity
        sku
        subtotal
        tax
        taxTotal
        title
        total
        updatedAt
        id
        fulfillmentStatus
        fulfilledAt
        trackingUrl
        trackingId
        variantId
        deliveredAt
        estimatedDeliveredAt
      }
    }
  }
`;

export const GET_PROTECTED_ORDERS_WITH_NO_CLAIMS = gql`
  query orders($storeId: uuid!, $number: String, $limit: Int) {
    orders(
      where: {
        storeId: { _eq: $storeId }
        number: { _ilike: $number }
        _not: { claims: {} }
      }
      limit: $limit
    ) {
      number
      orderId
      protectionStatus
      protectionTotal
      total
      customerName
      customerEmail
      customerPhone
      isReorder
      createdAt
      updatedAt
      cancelledAt
      id
      subtotal
      claims {
        id
      }
      items {
        imageSrc
        orderId
        price
        quantity
        sku
        subtotal
        tax
        taxTotal
        title
        total
        updatedAt
        id
        fulfillmentStatus
        fulfilledAt
        trackingUrl
        trackingId
        variantId
        deliveredAt
        estimatedDeliveredAt
      }
    }
  }
`;

export const GET_ORDER_BY_NUMBER = gql`
  query orders($storeId: uuid!, $number: String!) {
    orders(where: { storeId: { _eq: $storeId }, number: { _eq: $number } }) {
      number
      orderId
      protectionStatus
      protectionTotal
      total
      discountTotal
      customerName
      customerEmail
      customerPhone
      isReorder
      createdAt
      updatedAt
      id
      cancelledAt
      subtotal
      claims {
        id
      }
      items {
        imageSrc
        orderId
        price
        quantity
        sku
        subtotal
        tax
        taxTotal
        title
        total
        updatedAt
        id
        fulfillmentStatus
        fulfilledAt
        trackingUrl
        trackingId
        variantId
        deliveredAt
        estimatedDeliveredAt
      }
    }
  }
`;

export const FILTER_ORDERS = gql`
  query orders(
    $filters: orders_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [orders_order_by]!
  ) {
    orders(
      where: $filters
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      number
      isReorder
      orderId
      protectionStatus
      protectionTotal
      total
      customerName
      customerEmail
      customerPhone
      isReorder
      createdAt
      id
      subtotal
      cancelledAt
      trackingStatus
      trackings(where: { status: { _eq: "ACTIVE" } }) {
        trackingNumber
        trackingCompany
        defaultTrackingUrl
        status
        trackerId
        trackerStatus
        estDeliveryDate
        orderItem {
          title
          quantity
        }
      }
      claims {
        id
        number
      }
      items {
        id
        title
        trackingId
      }
    }
    ordersAggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_PROTECTED_ORDERS_COUNT = gql`
  query protectedOrders($filters: orders_bool_exp) {
    ordersAggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

/************
 *
 * CLAIM queries
 * **********
 */

export const GET_CLAIM = gql`
  query claims($storeId: uuid!, $id: uuid!) {
    claims(where: { storeId: { _eq: $storeId }, id: { _eq: $id } }) {
      createdAt
      date
      id
      notes
      customerNotes
      number
      orderId
      status
      storeId
      tags
      reason
      updatedAt
      total
      preferredResolution
      preferredResolutionNotes
      suggestedRefund
      createdBy {
        displayName
        id
      }
      images(order_by: { createdAt: desc }, limit: 5) {
        id
        url
      }
      videos(order_by: { createdAt: desc }, limit: 5) {
        id
        url
      }
      items {
        id
        reason
        resolution
        total
        quantity
        settlement {
          quantity
          claimItem {
            quantity
          }
          solvedByRule {
            title
          }
        }
        orderItem {
          imageSrc
          price
          subtotal
          taxTotal
          total
          discountTotal
          sku
          quantity
          id
          title
          fulfillmentStatus
          fulfilledAt
          trackingUrl
          trackingId
          variantId
          deliveredAt
          estimatedDeliveredAt
        }
      }
      resolution
      settlements {
        id
        resolution
        notes
        metadata
        message
        items {
          id
          claimItem {
            id
            orderItem {
              price
              quantity
              sku
            }
          }
          metadata
          updated_at
        }
        updatedBy {
          displayName
          email
          id
          avatarUrl
        }
        updatedAt
      }
      order {
        number
        customerName
        customerEmail
        customerPhone
        tax
        total
        discountTotal
        fulfillmentStatus
        protectionStatus
        protectionTotal
        createdAt
        subtotal
        paymentCurrencyCode
        items {
          updatedAt
          total
          discountTotal
          title
          taxTotal
          tax
          subtotal
          sku
          quantity
          price
          orderId
          imageSrc
          id
          createdAt
          trackingUrl
          trackingId
        }
      }
    }
    claimsAggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CLAIMS = gql`
  query claims(
    $storeId: uuid!
    $limit: Int
    $offset: Int
    $order_by: [claims_order_by]!
  ) {
    claims(
      where: { storeId: { _eq: $storeId } }
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      createdAt
      date
      id
      notes
      number
      orderId
      status
      storeId
      tags
      reason
      updatedAt
      total
      images {
        id
      }
      videos {
        id
      }
      items {
        id
      }
      resolution
      order {
        number
        customerName
        customerEmail
        customerPhone
        protectionTotal
        total
        protectionStatus
        createdAt
        items {
          id
          trackingId
        }
      }
    }
    claimsAggregate(where: { storeId: { _eq: $storeId } }) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CLAIMS_COUNT = gql`
  query getClaimsCount($storeId: uuid!) {
    totalClaims: claimsAggregate(where: { storeId: { _eq: $storeId } }) {
      aggregate {
        count
      }
    }
    resolvedClaims: claimsAggregate(
      where: { storeId: { _eq: $storeId }, status: { _eq: RESOLVED } }
    ) {
      aggregate {
        count
      }
    }
    newClaims: claimsAggregate(
      where: { storeId: { _eq: $storeId }, status: { _eq: NEW } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_CLAIMS_BY = gql`
  query claims($order_by: [claims_order_by]!, $storeId: uuid!) {
    claims(order_by: $order_by, where: { storeId: { _eq: $storeId } }) {
      createdAt
      date
      id
      notes
      number
      orderId
      status
      storeId
      tags
      reason
      updatedAt
      total
      images {
        id
      }
      videos {
        id
      }
      items {
        id
      }
      resolution
      order {
        number
        customerName
        customerEmail
        customerPhone
        total
        protectionStatus
        protectionTotal
        createdAt
      }
    }
    claimsAggregate(
      where: { storeId: { _eq: $storeId } }
      order_by: $order_by
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_RESOLVED_CLAIMS_COUNT = gql`
  query claims($storeId: uuid!, $statusType: claim_statuses_enum!) {
    claimsAggregate(
      where: { storeId: { _eq: $storeId }, statusType: { _eq: $statusType } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const FILTER_CLAIMS = gql`
  query claims(
    $filters: claims_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [claims_order_by]!
  ) {
    claims(
      where: $filters
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      createdAt
      date
      id
      notes
      number
      orderId
      status
      storeId
      reason
      tags
      total
      updatedAt
      images {
        id
      }
      videos {
        id
      }
      items {
        id
      }
      resolution
      order {
        number
        customerName
        customerEmail
        customerPhone
        total
        protectionStatus
        protectionTotal
        createdAt
      }
    }
    claimsAggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

/************
 *
 * STORE queries
 * **********
 */

export const GET_STORE_PROPERTIES = gql`
  query store($storeId: uuid!) {
    store(id: $storeId) {
      orderSettings {
        deadline
        shipping
      }
      storeClaimsSettings {
        notifyCustomerChecked
      }
      users {
        user {
          avatarUrl
          displayName
          email
          id
        }
        role
      }
      shouldUpdateAppInstall
      isStoreShopifyPlus
      completedSteps
      contactEmail
      contactPhone
      createdAt
      currency
      id
      name
      invites {
        email
        user {
          displayName
        }
      }
      logoUrl
      bannerUrl
      policyUrl
      requireDeliveryGuaranteePolicy
      resolvePortalOptions
      customResolutionNotes
      plan {
        active
        chargeUrl
        chargeConfirmed
        createdAt
        endDate
        id
        startDate
        storeId
        planId
      }
      protectionSettings {
        protectionType
        variantIds
      }
      store
      type
      itemFulfillmentImmediate
      widgetAutoOptIn
      widgetShowCart
      reordersDiscount
      excludedProductSkus
      tagsExcludedCustomersAutoOptIn
      uninstalledAt
      trialEndsAt
    }
  }
`;

export const GET_STORE_ANALYTICS = gql`
  query storeAnalytics(
    $storeId: uuid!
    $afterDate: timestamptz
    $beforeDate: timestamptz
  ) {
    totalOrders: ordersAggregate(
      where: {
        storeId: { _eq: $storeId }
        _and: [
          { createdAt: { _gte: $afterDate } }
          { createdAt: { _lte: $beforeDate } }
        ]
      }
    ) {
      nodes {
        createdAt
      }
      aggregate {
        total: count
      }
    }
    protectedOrders: ordersAggregate(
      where: {
        storeId: { _eq: $storeId }
        _and: [
          { createdAt: { _gte: $afterDate } }
          { createdAt: { _lte: $beforeDate } }
        ]
        protectionTotal: { _is_null: false }
      }
    ) {
      nodes {
        createdAt
      }
      aggregate {
        total: count
      }
    }
    unprotectedOrders: ordersAggregate(
      where: {
        storeId: { _eq: $storeId }
        _and: [
          { createdAt: { _gte: $afterDate } }
          { createdAt: { _lte: $beforeDate } }
        ]
        protectionTotal: { _is_null: true }
      }
    ) {
      aggregate {
        total: count
      }
    }
    totalClaims: claimsAggregate(
      where: {
        storeId: { _eq: $storeId }
        _and: [
          { createdAt: { _gte: $afterDate } }
          { createdAt: { _lte: $beforeDate } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    newClaims: claimsAggregate(
      where: {
        storeId: { _eq: $storeId }
        _and: [
          { createdAt: { _gte: $afterDate } }
          { createdAt: { _lte: $beforeDate } }
        ]
        status: { _eq: NEW }
      }
    ) {
      aggregate {
        count
      }
    }
    reviewClaims: claimsAggregate(
      where: {
        storeId: { _eq: $storeId }
        _and: [
          { createdAt: { _gte: $afterDate } }
          { createdAt: { _lte: $beforeDate } }
        ]
        status: { _eq: IN_REVIEW }
      }
    ) {
      aggregate {
        count
      }
    }
    deniedClaims: claimsAggregate(
      where: {
        storeId: { _eq: $storeId }
        _and: [
          { createdAt: { _gte: $afterDate } }
          { createdAt: { _lte: $beforeDate } }
        ]
        status: { _eq: DENIED }
      }
    ) {
      aggregate {
        count
      }
    }
    resolvedClaims: claimsAggregate(
      where: {
        storeId: { _eq: $storeId }
        _and: [
          { createdAt: { _gte: $afterDate } }
          { createdAt: { _lte: $beforeDate } }
        ]
        status: { _eq: RESOLVED }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_STORE_USER_INVITES = gql`
  query storeAnalytics($storeId: uuid!) {
    storeUserInvites(
      order_by: { createdAt: desc }
      where: { storeId: { _eq: $storeId } }
    ) {
      id
      email
      role
      createdAt
    }
  }
`;

export const GET_BILLING_PLANS = gql`
  query getBillingPlans($storeId: uuid!) {
    plans(
      order_by: { priceMonthly: asc }
      where: {
        _or: [{ public: { _eq: true } }, { storeId: { _eq: $storeId } }]
      }
    ) {
      id
      name
      priceMonthly
      description
      protectionPercentage
      terms
      extraDescription
      oldPrice
      storeId
      storesPlans(where: { storeId: { _eq: $storeId } }) {
        id
        active
      }
    }
  }
`;

export const GET_ORDERS_CLAIMS_COUNT = gql`
  query totalOrdersClaims($storeId: uuid!) {
    totalOrders: ordersAggregate(
      where: {
        storeId: { _eq: $storeId }
        protectionTotal: { _is_null: false }
      }
    ) {
      aggregate {
        total: count
      }
    }
    totalClaims: claimsAggregate(
      where: { storeId: { _eq: $storeId }, status: { _eq: NEW } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/************
 *
 * BILLS queries
 * **********
 */

export const GET_BILLINGS_DATA = gql`
  query getBillings($storeId: uuid!) {
    bills(
      where: { storeId: { _eq: $storeId } }
      order_by: { createdAt: desc }
    ) {
      amount
      billType
      createdAt
      currencyCode
      date
      id
      invoiceUrl
      number
      paid
    }
  }
`;

export const SEARCH_BILLINGS_DATA = gql`
  query searchBillings($storeId: uuid!, $value: String!) {
    bills(
      where: {
        number: { _cast: { String: { _ilike: $value } } }
        storeId: { _eq: $storeId }
      }
    ) {
      amount
      billType
      createdAt
      currencyCode
      date
      id
      invoiceUrl
      number
      paid
    }
  }
`;

export const FILTER_BILLINGS = gql`
  query filterBillings(
    $filters: bills_bool_exp
    $order_by: [bills_order_by!]!
  ) {
    bills(where: $filters, order_by: $order_by) {
      amount
      billType
      createdAt
      currencyCode
      date
      id
      invoiceUrl
      number
      paid
    }
  }
`;

/************
 *
 * DASHBOARD query
 * **********
 */

export const GET_DASHBOARD_ANALYTICS = gql`
  query DashboardAnalytics(
    $storeId: uuid!
    $startDate: timestamptz!
    $endDate: timestamptz!
  ) {
    protectedOrders: orders(
      where: {
        storeId: { _eq: $storeId }
        protectionTotal: { _is_null: false }
        isReorder: { _eq: false }
        createdAt: { _gte: $startDate, _lte: $endDate }
      }
    ) {
      id
      number
      createdAt
      protectionTotal
      protectionStatus
      total
      isReorder
      cancelledAt
    }
    protectionFees: protectionFeesAggregate(
      where: {
        storeId: { _eq: $storeId }
        createdAt: { _gte: $startDate, _lte: $endDate }
      }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
    claimsPaid: claims(
      where: {
        storeId: { _eq: $storeId }
        resolution: { _in: [REFUND, REORDER, OTHER] }
        createdAt: { _gte: $startDate, _lte: $endDate }
      }
    ) {
      id
      createdAt
      number
      total
      order {
        number
        protectionTotal
      }
    }
  }
`;

/************
 *
 * ADMIN queries
 * **********
 */

export const GET_ALL_MERCHANT_EMAILS = gql`
  query users($filters: users_bool_exp) {
    users(where: $filters) {
      id
      email
    }
  }
`;

export const GET_ADMIN_USERS = gql`
  query users(
    $filters: users_bool_exp
    $limit: Int
    $offset: Int
    $order_by: [users_order_by]!
  ) {
    users(
      where: $filters
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      avatarUrl
      createdAt
      displayName
      email
      id
      lastSeen
    }
    usersAggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

export const FILTER_ADMIN_USERS = gql`
  query users($filters: users_bool_exp, $order_by: [users_order_by]!) {
    users(where: $filters, order_by: $order_by) {
      avatarUrl
      createdAt
      displayName
      email
      id
      lastSeen
    }
    usersAggregate(where: $filters) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ADMIN_USER_BY_ID = gql`
  query users($merchantId: uuid!) {
    users(where: { id: { _eq: $merchantId } }) {
      avatarUrl
      createdAt
      defaultRole
      isAnonymous
      locale
      metadata
      roles {
        role
      }
      displayName
      email
      id
      lastSeen
      stores {
        store {
          createdAt
          completedSteps
          store
          id
          logoUrl
          type
          url
          widgetShowCart
          widgetAutoOptIn
          name
          storeCredit
          domains {
            id
            domain
          }
          plan {
            active
          }
          autoOptInProbability
        }
      }
      invites {
        user {
          avatarUrl
          createdAt
          displayName
          email
          id
          lastSeen
        }
      }
    }
  }
`;

export const GET_ALL_ANNOUNCEMENTS = gql`
  query announcements {
    announcements(order_by: { createdAt: desc }) {
      active
      text
      type
      id
      createdAt
    }
  }
`;

export const GET_ACTIVE_ANNOUNCEMENTS = gql`
  query announcements {
    announcements(
      order_by: { createdAt: desc }
      limit: 1
      where: { active: { _eq: true } }
    ) {
      active
      text
      type
      id
      createdAt
    }
  }
`;

/*
  **
    Tracking and Email Notifications
  **
*/

export const GET_TRACKING_TEMPLATES = gql`
  query trackingTemplates($storeId: uuid!) {
    trackingTemplates(where: { storeId: { _eq: $storeId } }) {
      active
      isTrackingDefault
      createdAt
      eventType
      id
      platformIntegrationType
      sentCount
      storeId
      subject
      templateHtml
      templateJson
      updatedAt
      usage
    }
  }
`;

/*
  **
    Brand Email
  **
*/

export const GET_CUSTOMER_NOTIFICATON_SETTINGS = gql`
  query customerNotificationsSettings($storeId: uuid!) {
    customerNotificationsSettings(where: { storeId: { _eq: $storeId } }) {
      id
      replyToEmail
      replyToName
      senderEmail
      senderName
    }
  }
`;

/*
  **
    Custom Domain and Cover Image for Tracking Page
  **
*/
export const GET_STORE_TRACKING_PAGE_SETTINGS = gql`
  query storesSettings($storeId: uuid!) {
    storesSettings(where: { storeId: { _eq: $storeId } }) {
      customDomain
      trackingPortalCoverImage
    }
  }
`;

/**
 Claim Automation Rules
 *
 */
export const GET_CLAIM_AUTOMATION_RULES = gql`
  query claimRules($storeId: uuid!) {
    claimRules(
      where: { storeId: { _eq: $storeId } }
      order_by: { priority: asc }
    ) {
      id
      ruleBeforeTransform
      storeId
      active
      action
      actionNotes
      priority
      statements
      title
    }
  }
`;

/*
  **
  Referral Program from User
  **
  */
export const GET_USER_REFERRAL_PROGRAM_INFO = gql`
  query {
    getReferralProgramInfo {
      access_token
      expires_in
      hasAccount
    }
  }
`;

/*
  **
  Install Requests
  **
  */
export const GET_INSTALL_REQUESTS = gql`
  query ($storeId: uuid!) {
    installRequests(where: { storeId: { _eq: $storeId } }) {
      id
      storeId
      content
      ticketId
      ticketLink
      status
      createdAt
      updatedAt
    }
  }
`;
